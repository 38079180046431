<template>
  <div class="circleZr">
    <div class="flex-column batComentZr">
      <pageHead class="messageMainView_head" title="圈主管理权转让" />
      <van-row class="flex-row-h-center search_box">
        <van-col span="24">
          <circleSearchs
            placeholder="请输入"
            @input="handleInput"
            :disabled="false"></circleSearchs>
        </van-col>
      </van-row>
      <div
        infinite-scroll-throttle-delay="500"
        infinite-scroll-distance="50"
        v-infinite-scroll="loadMore"
        class="trans-content">
        <!-- 列表 -->
        <van-radio-group @change="handleChange" v-model="selectMemberId">
          <van-radio checked-color="#FE5800" v-for="(item,index) in vanSwipeList" :key="index" :name="item.userBasicVO.userId"
            icon-size="24px">
            <div class="user-wrap">
              <div class="wrap-left">
                <img :src="item.avatar" />
              </div>
              <div class="wrap-right">
                <div class="user-name" v-html="item.username"></div>
                <div class="user-dept">{{ item.deptStr }}</div>
              </div>
            </div>
          </van-radio>
        </van-radio-group>
        <!-- 无数据展示 -->
        <template v-if="vanSwipeList.length === 0">
          <div class="hotCircle_noData">
            <div class="noData_box">
              <div class="noData_img"></div>
              <span>{{ noDataInfo.title1 || '暂未数据' }}</span>
            </div>
          </div>
        </template>
        <div class="footer">
          <div class="button-box">
            <van-button @click="makeOverMaster" :disabled="!selectMemberId" round color="#FE5800" type="primary" block>
              转让圈主
            </van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import batComentZr from './batComentZr.vue'
import {getListMember, updateTransferLeader} from "@/api/circle";
import pageHead from '@/components/pageHead'
import circleSearchs from '@/components/searchBox'

export default {
  name: 'circleZr', // 成员管理
  components: {
    pageHead,
    circleSearchs
    // batComentZr,
  },
  data() {
    return {
      selectMemberId: '', // 选择的成员id
      vanSwipeList: [],
      noDataInfo: {
        title1: '暂无相关人员'
      },
      paginationData: {
        circleId: 0,
        pageNum: 1,
        pageSize: 10,
        username: ''
      },
      finished: false
    }
  },
  created() {
    if (this.$route.query.id) {
      this.paginationData.circleId = this.$route.query.id
      this.getMemberList()
    }
  },
  methods: {
    // 加载更多
    loadMore() {
      if (!this.finished && this.vanSwipeList.length > 0) {
        this.loading = true
        this.paginationData.pageNum++
        this.getMemberList()
      }
    },
    handleChange(value) {
      console.log(value)
    },
    handleInput(value) {
      this.finished = false
      this.paginationData.username = value
      this.paginationData.pageNum = 1
      this.getMemberList('reset')
    },
    // 分页获取圈成员列表
    async getMemberList(type) {
      if (!this.paginationData.username) {
        delete this.paginationData.username
      }
      const res = await getListMember(this.paginationData) || {}
      const {records} = res || []
      if (records.length < 10) {
        this.finished = true
      }
      const list = records.map(item => {
        return {
          ...item,
          ...item.userBasicVO
        }
      })
      if (type === 'reset') {
        this.vanSwipeList = list
      } else {
        this.vanSwipeList = this.vanSwipeList.concat(list)
      }
      this.vanSwipeList = this.vanSwipeList.filter(item => item.identity !== 1)
    },
    // 转让圈主
    makeOverMaster() {
      const name = this.vanSwipeList.filter(item => item.userBasicVO.userId === this.selectMemberId)[0].userBasicVO.username
      console.log(name)
      this.$emit('makeOverMaster', this.selectMemberId)
      this.$dialog.confirm({
        title: '转让圈主',
        message: `转让后“${name}”将成为新的圈主，你将立即失去圈主身份`,
      }).then(() => {
        this.updateTransferLeader(name)
      })
    },
    async updateTransferLeader(name) {
      const data = {
        transferUserId: this.selectMemberId,
        circleId: this.paginationData.circleId
      }
      await updateTransferLeader(data)
      this.$toast({
        message: '转让成功',
        position: 'top'
      })
      this.backHome(name)
    },
    backHome(name) {
      this.$dialog.alert({
        title: '返回主页',
        message: `您已经将圈子的权限转给了"${name}"，请返回主页`,
        theme: 'round-button',
      }).finally(() => {
        this.$router.replace('/app/home/main')
        // on close
      });
    },
  }
}
</script>

<style scoped>
.circleZr {
  height: 100vh;
  overflow: hidden;
}
</style>

<style lang="scss" scoped>
::v-deep {
  .van-radio {
    padding: 16px 24px;
  }
}

.trans-content {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 110px;
}

.user-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;

  .wrap-left {
    display: flex;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .wrap-right {
    line-height: 22px;
    margin-left: 10px;

    .user-name {
      font-size: 14px;
      font-weight: 500;
      text-align: LEFT;
    }

    .user-dept {
      font-size: 14px;
      color: #6d7278;
    }
  }
}

.batComentZr {
  position: relative;
  background: #fff;
  overflow: hidden;
  height: 100%;

  .member-item {
    .name {
      margin-left: 10px;
      font-size: 14px;
      color: #000000;
    }
  }

  .van_cell_box {
    ::v-deep .van-cell {
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #f4f4f4;
      font-size: 16px;
      color: #000000;
    }
  }

  .circleList {
    overflow: scroll;
    width: 230px;
    float: left;

    .circleLi {
      display: flex;
    }

    .item_cir:first-child {
      margin-left: 0;
    }

    .item_cir {
      margin-right: 5px;

      .cir_title {
        font-size: 12px;
        color: #333333;
        text-indent: 6px;
        margin-top: 6px;
        letter-spacing: 0;
      }

      .van_image_box {
        position: relative;

        .van_image_lsBox {
          position: absolute;
          right: 0;
          z-index: 88;
          top: -10px;

          .van_image_ls {
            display: inline-block;
            width: 14px;
            height: 14px;
            color: #fff;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.75);
            position: relative;

            &::after {
              content: '-';
              position: absolute;
              left: 2px;
              top: -9px;
            }
          }
        }

        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
      }
    }
  }

  .search_box {
    padding-top: 12px;

    ::v-deep .van-search__content {
      height: 41px;
      padding-left: 15px;
    }

    ::v-deep .van-cell {
      line-height: 31px;
    }
  }

  ::v-deep .van-search__content {
    border-radius: 70px;
  }

  .cancel {
    position: relative;
    left: 10px;
    top: 3px;

    .cancel_title {
      font-size: 14px;
      text-align: center;
      color: #000000;
      line-height: 14px;
    }
  }

  .vant_List {
    margin-top: 16px;
    height: 480px;
    overflow: scroll;

    .van-swipe-cell__wrapper {
      display: flex;
      border-bottom: 1px solid #f4f4f4;
    }

    ::v-deep .van-card__title {
      padding-left: 0;
    }

    ::v-deep .van-card {
      padding-left: 10px;
      padding: 0 0 0 10px;
    }

    ::v-deep .van-card:not(:first-child) {
      margin-top: 0;
    }

    .van-swipe-cell:first-child {
      margin-top: 0;
    }

    .van-swipe-cell {
      margin-top: 16px;
      padding: 0 15px;
    }

    .checkbox_icon {
      width: 21px;
      height: 21px;
      position: relative;
      top: 10px;
      box-sizing: border-box;
      display: inline-block;
      opacity: 0.9;
      border-radius: 50%;
      border: 2px solid #dcdcdc;
    }

    .active_icon {
      width: 21px;
      height: 21px;
      position: relative;
      top: 10px;
      box-sizing: border-box;
      display: inline-block;
      background: url('~@/assets/imgs/circle/Slice43@2x.png');
      background-size: 100%;
    }
  }

  .hotCircle_noData {
    display: flex;
    height: 450px;
    justify-content: center;
    align-items: center;
    text-align: center;

    .noData_box {
      margin-bottom: 16px;

      .noData_img {
        width: 212px;
        height: 114px;
        background: url('~@/assets/imgs/circle/Slice39@2x.png') no-repeat;
        background-size: cover;
        margin: 0 auto;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    span {
      font-size: 12px;
      color: #6d7278;
    }
  }

  .footer {
    width: 100vw;
    position: fixed;
    bottom: 0;
    height: 108px;
    background: #fff;
    border-top: 1px solid #f4f4f4;

    .button-box {
      margin: 20px 20px 0;
    }
  }
}
</style>
